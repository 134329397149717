/* eslint-disable */
import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo/Seo';
import StructuredTextExtendedContent from '../components/structuredTextExtendedContent';
import BreadCrumbs from '../components/breadCrumbs/BreadCrumbs';

const SingleArticlePage = ({ data: { allArticles }, pageContext }) => {
	const activeSite = pageContext?.locale || '';
	// const allModules = allData?.nodes?.[0]?.chooseModule || '';
	const seoData = allArticles?.nodes?.[0]?.seoSettings || '';
	const structuredTextData = allArticles?.nodes?.[0]?.content || '';
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || '';
	const preparedSitemapSimplify = pageContext?.preparedSitemapSimplify || [];
	return (
		<>
			<Seo seoData={seoData} seoGlobal={seoGlobal} />
			<StructuredTextExtendedContent
				data={structuredTextData}
				activeSite={activeSite}
				preparedSitemapSimplify={preparedSitemapSimplify}
				isSingleNewsPage
			/>
			<BreadCrumbs data="" activeCrumb={pageContext?.page?.singlePageName} activeSite={activeSite} singleArticle />
		</>
	);
};
export default SingleArticlePage;

export const query = graphql`
	query ($id: String) {
		allArticles: allDatoCmsArticle(filter: { id: { eq: $id } }) {
			nodes {
				id
				singlePageName
				cardHeader
				cardDescription
				buttonText
				slug
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				meta {
					createdAt
				}
				cardPhoto {
					gatsbyImageData
					alt
				}
				content {
					value
					__typename
					links {
						__typename
						...singlePageLink
						...moduleTable
						...moduleSeeAlso
						...videoMiniatures
						...dropdownList
					}
					blocks {
						__typename
						...blockImageLarge
						...blockImageSmall
						...blockGallery
						...blockMargin
						...alignTextBlock
					}
				}
			}
		}
	}
`;
